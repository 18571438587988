import * as React from "react"
import Layout from "../components/layout";
import {graphql} from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {RichText} from "prismic-reactjs";
import IconLink from "../components/icon-link";
import GatsbyLink from "../components/gatsby-link";
import Prinzip from "../components/prinzip";

const PrinzipienPage = ({ data }) => {

  const content = data.prismicPrinzipienUbersicht.data;
  return (
    <Layout title={RichText.asText(content.titel.raw)}>
      <section>
        <Row className="flex-md-row flex-column-reverse ">
          <Col>
            <h1>
              {RichText.asText(content.titel.raw)}
            </h1>
            <RichText render={content.beschreibung.raw} serializeHyperlink={GatsbyLink}/>
          </Col>
        </Row>
      </section>
      {content.prinzipien.map((prinzip, index) => {
        return (
          <section key={`prinzip-${index}`}>
            <Row>
              <Col>
                <Prinzip index={index} title={prinzip.prinzip_titel} description={prinzip.prinzip_beschreibung}/>
              </Col>
            </Row>
          </section>
        );
      })}
      <section>
        <Row>
          <Col className="text-center text-sm-left">
            <RichText render={content.uberleitung.raw} serializeHyperlink={GatsbyLink}/>
            <div className="mt-5 d-flex flex-column justify-content-center align-items-center flex-sm-row justify-content-sm-between">
              {content.body.map((node, index) => (
                <IconLink key={`link-${index}`} to={node.primary.link.url} icon={node.primary.icon} left={node.primary.icon === 'FaArrowLeft'}>
                  {node.primary.link_label}
                </IconLink>
              ))}
            </div>
          </Col>
        </Row>
      </section>
    </Layout>
  )
}

export const query = graphql`
query PrinzipienPageQuery {
  prismicPrinzipienUbersicht {
    data {
      prinzipien {
        prinzip_beschreibung {
          raw
        }
        prinzip_titel {
          raw
        }
      }
      beschreibung {
        raw
      }
      titel {
        raw
      }
      uberleitung {
        raw
      }
      body {
        ... on PrismicPrinzipienUbersichtBodyLinkWithIcon {
          primary {
            icon
            link {
              url
            }
            link_label
          }
        }
      }
    }
  }
}
`

export default PrinzipienPage
