import * as React from "react"
import {RichText} from "prismic-reactjs";
import GatsbyLink from "./gatsby-link";
import {useInView} from "react-intersection-observer";

const Prinzip = ({index, title, description}) => {

  const [ref, inView, ] = useInView({
    threshold: 0.5,
    triggerOnce: true
  })

  return (
    <div ref={ref} className={`prinzip d-flex flex-column justify-content-center text-center opacity-0 ${inView ? 'slide-up' : ''}`}>
      <p className="prinzip-index mb-4" style={{fontWeight: 400}}>
        <span className="brush brush-center">Prinzip {index + 1}</span>
      </p>
      <h2 className="mb-4">
        {RichText.asText(title.raw)}
      </h2>
      <RichText render={description.raw} serializeHyperlink={GatsbyLink}/>
    </div>
  );
}

export default Prinzip
